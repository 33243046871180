.anime {
  width: 140px;
  height: 140px;
}

.anime > ul {
  position: relative;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
}

.anime > ul li {
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.anime > ul #a {
  animation: a 1s ease-in-out infinite;
  top: -40px;
  left: -40px;
}

.anime > ul #b {
  animation: b 1s ease-in-out infinite;
  top: -40px;
  left: 0;
}

.anime > ul #c {
  animation: c 1s ease-in-out infinite;
  top: -40px;
  left: 40px;
}

.anime > ul #d {
  animation: d 1s ease-in-out infinite;
  top: 0;
  left: -40px;
}

.anime > ul #e {
  animation: e 1s ease-in-out infinite;
  top: 0;
  left: 0;
}

.anime > ul #f {
  animation: f 1s ease-in-out infinite;
  top: 0;
  left: 40px;
}

.anime > ul #g {
  animation: g 1s ease-in-out infinite;
  top: 40px;
  left: -40px;
}

.anime > ul #h {
  animation: h 1s ease-in-out infinite;
  top: 40px;
  left: 0;
}

.anime > ul #i {
  animation: i 1s ease-in-out infinite;
  top: 40px;
  left: 40px;
}

@keyframes a {
  50% {
    top: 0;
    left: -40px;
  }

  100% {
    top: 0;
    left: -40px;
  }
}

@keyframes b {
  50% {
    top: -40px;
    left: -40px;
  }

  100% {
    top: -40px;
    left: -40px;
  }
}

@keyframes c {
  50% {
    top: -40px;
    left: 0;
  }

  100% {
    top: -40px;
    left: 0;
  }
}

@keyframes d {
  50% {
    top: 40px;
    left: -40px;
  }

  100% {
    top: 40px;
    left: -40px;
  }
}

@keyframes f {
  50% {
    top: -40px;
    left: 40px;
  }

  100% {
    top: -40px;
    left: 40px;
  }
}

@keyframes g {
  50% {
    top: 40px;
    left: 0;
  }

  100% {
    top: 40px;
    left: 0;
  }
}

@keyframes h {
  50% {
    top: 40px;
    left: 40px;
  }

  100% {
    top: 40px;
    left: 40px;
  }
}

@keyframes i {
  50% {
    top: 0;
    left: 40px;
  }

  100% {
    top: 0;
    left: 40px;
  }
}
