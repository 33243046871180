/* @font-face {
  font-family: "Eina-Light";
  src: url("../assets/fonts/Eina01-Light.ttf");
} */
/* @font-face {
  font-family: "Eina-Regular";
  src: url("../assets/fonts/Eina01-Regular.ttf");
} */
/* @font-face {
  font-family: "Eina-SemiBold";
  src: url("../assets/fonts/Eina01-SemiBold.ttf");
} */
/* @font-face {
  font-family: "Eina-Bold";
  src: url("../assets/fonts/Eina01-Bold.ttf");
} */

@font-face {
  font-family: 'Eina01';
  src: url("../assets/fonts/Eina01-Regular.ttf");
}
@font-face {
  font-family: 'Eina01';
  src: url("../assets/fonts/Eina01-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: 'Eina01';
  src: url("../assets/fonts/Eina01-Bold.ttf");
  font-weight: 700;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .clickable {
    @apply transition-opacity hover:opacity-80 active:opacity-60;
  }

  .text-normal {
    @apply font-[Eina01] font-normal text-black;
  }

  .text-semibold {
    @apply font-[Eina01] font-semibold text-black;
  }

  .text-bold {
    @apply font-[Eina01] font-bold text-black;
  }
}

.fade-drawer-enter {
  transform: translateX(100%);
}
.fade-drawer-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.fade-drawer-exit {
  transform: translateX(0);
}
.fade-drawer-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

.fade-dropdown-enter {
  transform: scaleY(1.1);
}

.fade-dropdown-enter-active {
  transform-origin: top;
  transform: scaleY(1);
  transition: transform 300ms;
}

.fade-dropdown-exit {
  transform: scaleY(1.1);
}

.fade-dropdown-exit-active {
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 300ms;
}

.fade-enter {
  opacity: 0;
  transform: scale(1.1);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-route-enter {
  opacity: 0;
}

.fade-route-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-route-exit {
  opacity: 1;
}

.fade-route-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.fade-banner-enter {
  opacity: 0;
}
.fade-banner-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-banner-exit {
  opacity: 1;
}
.fade-banner-exit-active {
  height: 0;
  opacity: 0;
  transition: opacity 300ms, height 300ms;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

body {
  margin: 0;
  font-family: "Eina01-Regular", "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC",
    "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.grecaptcha-badge {
  visibility: hidden;
}
