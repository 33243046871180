body {
  --gray-1: 247, 248, 250;
  --gray-2: 242, 243, 245;
  --gray-4: 201, 205, 212;
  --gray-8: 78, 89, 105;
  --arcoblue-1: 232, 243, 255;
  --arcoblue-3: 148, 191, 255;
  --arcoblue-6: 22, 93, 255;
  --primary-1: var(--arcoblue-1);
  --primary-3: var(--arcoblue-3);
  --primary-6: var(--arcoblue-6);
  --color-neutral-1: rgb(var(--gray-1));
  --color-neutral-2: rgb(var(--gray-2));
  --color-neutral-4: rgb(var(--gray-4));
  --color-neutral-8: rgb(var(--gray-8));
  --color-fill-1: var(--color-neutral-1);
  --color-fill-2: var(--color-neutral-2);
  --color-text-2: var(--color-neutral-8);
  --color-text-4: var(--color-neutral-4);
  --color-primary-light-1: rgb(var(--primary-1));
  --color-primary-light-3: rgb(var(--primary-3));
  --border-radius-small: 2px;
  --border-radius-circle: 50%;
}

.arco-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  width: 1em;
  height: 1em;
  vertical-align: -2px;
  stroke: currentColor;
}
.arco-icon[fill="currentColor"] {
  fill: currentColor;
  stroke: none;
}
.arco-icon[stroke="currentColor"] {
  stroke: currentColor;
  fill: none;
}
.arco-icon[fill="currentColor"][stroke="currentColor"] {
  fill: currentColor;
  stroke: currentColor;
}
.arco-icon-loading {
  animation: arco-loading-circle 1s infinite cubic-bezier(0, 0, 1, 1);
}
@keyframes arco-loading-circle {
  100% {
    transform: rotate(360deg);
  }
}
.arco-icon-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
  line-height: 0;
}
.arco-icon-hover .arco-icon {
  position: relative;
  vertical-align: -0.09em;
}
.arco-icon-hover::before {
  content: "";
  position: absolute;
  display: block;
  border-radius: var(--border-radius-circle);
  background-color: transparent;
  box-sizing: border-box;
  transition: background-color 0.1s cubic-bezier(0, 0, 1, 1);
}
.arco-icon-hover:hover::before {
  background-color: var(--color-fill-2);
}
.arco-icon-hover.arco-icon-hover-disabled::before {
  opacity: 0;
}
.arco-icon-hover::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-mini::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-small::before {
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-large::before {
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
}
.arco-icon-hover-size-huge::before {
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%);
}

.arco-pagination {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.arco-pagination-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}
.arco-pagination-item {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: var(--border-radius-small);
  color: var(--color-text-2);
  border: 0 solid transparent;
  background-color: transparent;
  font-size: 14px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
}
.arco-pagination-item-prev,
.arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-item-disabled {
  cursor: not-allowed;
  background-color: transparent;
  color: var(--color-text-4);
  border-color: transparent;
}
.arco-pagination-item:not(.arco-pagination-item-disabled):not(.arco-pagination-item-active):hover {
  background-color: var(--color-fill-1);
  border-color: transparent;
  color: var(--color-text-2);
}
.arco-pagination-item-active {
  transition: color 0.2s cubic-bezier(0, 0, 1, 1), background-color 0.2s cubic-bezier(0, 0, 1, 1);
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  border-color: transparent;
}
.arco-pagination-item:not(:last-child) {
  margin-right: 8px;
}
.arco-pagination-item:focus-visible {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}
.arco-pagination-item-prev,
.arco-pagination-item-next {
  font-size: 12px;
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-pagination-item-prev:not(.arco-pagination-item-disabled):hover,
.arco-pagination-item-next:not(.arco-pagination-item-disabled):hover {
  background-color: var(--color-fill-1);
  color: rgb(var(--primary-6));
}
.arco-pagination-item-prev::after,
.arco-pagination-item-next::after {
  display: inline-block;
  content: ".";
  font-size: 0;
  vertical-align: middle;
}
.arco-pagination .arco-pagination-item-prev.arco-pagination-item-disabled,
.arco-pagination .arco-pagination-item-next.arco-pagination-item-disabled {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-pagination-item-jumper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.arco-pagination-jumper {
  margin-left: 8px;
}
.arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-pagination-jumper-text-goto {
  color: var(--color-text-3);
}
.arco-pagination .arco-pagination-jumper-input {
  width: 40px;
  text-align: center;
  margin-left: 8px;
  padding-left: 2px;
  padding-right: 2px;
}
.arco-pagination-option {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  min-width: 0;
  position: relative;
  margin-left: 8px;
  font-size: 14px;
  height: 32px;
  line-height: 0;
}
.arco-pagination-option .arco-select {
  width: auto;
}
.arco-pagination-option .arco-select-view-value {
  overflow: inherit;
  padding-right: 6px;
}
.arco-pagination-total-text {
  display: inline-block;
  height: 100%;
  color: var(--color-text-1);
  margin-right: 8px;
  font-size: 14px;
  line-height: 32px;
}
.arco-pagination-item-simple-pager {
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px 0 4px;
}
.arco-pagination-item-simple-pager .arco-pagination-jumper-separator {
  padding: 0 12px 0 12px;
}
.arco-pagination-simple .arco-pagination-item {
  margin-right: 0;
}
.arco-pagination-simple .arco-pagination-jumper {
  color: var(--color-text-1);
}
.arco-pagination-simple .arco-pagination-jumper {
  margin-left: 0;
}
.arco-pagination-simple .arco-pagination-jumper .arco-pagination-jumper-input {
  width: 40px;
  margin-left: 0;
}
.arco-pagination-simple .arco-pagination-item-prev,
.arco-pagination-simple .arco-pagination-item-next {
  color: var(--color-text-2);
  background-color: transparent;
}
.arco-pagination-simple .arco-pagination-item-prev:not(.arco-pagination-item-disabled):hover,
.arco-pagination-simple .arco-pagination-item-next:not(.arco-pagination-item-disabled):hover {
  background-color: var(--color-fill-1);
  color: rgb(var(--primary-6));
}
.arco-pagination-simple .arco-pagination-item-prev.arco-pagination-item-disabled,
.arco-pagination-simple .arco-pagination-item-next.arco-pagination-item-disabled {
  color: var(--color-text-4);
  background-color: transparent;
}
.arco-pagination-disabled {
  cursor: not-allowed;
}
.arco-pagination-disabled .arco-pagination-item,
.arco-pagination-disabled
  .arco-pagination-item:not(.arco-pagination-item-disabled):not(.arco-pagination-item-active):hover {
  cursor: not-allowed;
  background-color: transparent;
  border-color: transparent;
  color: var(--color-text-4);
}
.arco-pagination.arco-pagination-disabled .arco-pagination-item-active {
  background-color: var(--color-fill-1);
  border-color: transparent;
  color: var(--color-primary-light-3);
}
.arco-pagination-size-mini .arco-pagination-item {
  font-size: 12px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.arco-pagination-size-mini .arco-pagination-item-prev,
.arco-pagination-size-mini .arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-size-mini .arco-pagination-total-text {
  font-size: 12px;
  line-height: 24px;
}
.arco-pagination-size-mini .arco-pagination-option {
  font-size: 12px;
  height: 24px;
  line-height: 0;
}
.arco-pagination-size-mini .arco-pagination-jumper > span {
  font-size: 12px;
}
.arco-pagination-size-small .arco-pagination-item {
  font-size: 14px;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
}
.arco-pagination-size-small .arco-pagination-item-prev,
.arco-pagination-size-small .arco-pagination-item-next {
  font-size: 12px;
}
.arco-pagination-size-small .arco-pagination-total-text {
  font-size: 14px;
  line-height: 28px;
}
.arco-pagination-size-small .arco-pagination-option {
  font-size: 14px;
  height: 28px;
  line-height: 0;
}
.arco-pagination-size-small .arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-pagination-size-large .arco-pagination-item {
  font-size: 14px;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
}
.arco-pagination-size-large .arco-pagination-item-prev,
.arco-pagination-size-large .arco-pagination-item-next {
  font-size: 14px;
}
.arco-pagination-size-large .arco-pagination-total-text {
  font-size: 14px;
  line-height: 36px;
}
.arco-pagination-size-large .arco-pagination-option {
  font-size: 14px;
  height: 36px;
  line-height: 0;
}
.arco-pagination-size-large .arco-pagination-jumper > span {
  font-size: 14px;
}
.arco-pagination-rtl {
  direction: rtl;
}
.arco-pagination-rtl .arco-pagination-item:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.arco-pagination-rtl .arco-pagination-item-simple-pager {
  margin: 0 4px 0 12px;
}
.arco-pagination-rtl .arco-pagination-item-simple-pager .arco-pagination-jumper-separator {
  padding: 0 12px 0 12px;
}
.arco-pagination-rtl .arco-pagination-jumper {
  margin-left: 0;
  margin-right: 8px;
}
.arco-pagination-rtl .arco-pagination-jumper-input {
  margin-left: 0;
  margin-right: 8px;
}
.arco-pagination-rtl .arco-pagination-option {
  margin-left: 0;
  margin-right: 8px;
}
.arco-pagination-rtl .arco-pagination-option .arco-select-view-value {
  padding-right: 0;
  padding-left: 6px;
}
.arco-pagination-rtl .arco-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.arco-pagination-rtl.arco-pagination-simple .arco-pagination-item,
.arco-pagination-rtl.arco-pagination-simple .arco-pagination-jumper,
.arco-pagination-rtl.arco-pagination-simple .arco-pagination-jumper-input {
  margin-right: 0;
}
